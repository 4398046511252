<template>
  <div class="main_content">
    <div class="title_box">
      <span></span>
      <h5>商品管理</h5>
    </div>
    <div class="line"></div>
    <div class="operate_box">
      <div class="search_box">
        <div class="input_box">
          <el-input v-model="searchForm.productName" placeholder="商品名称"></el-input>
        </div>
        <div class="input_box">
          <el-select v-model="searchForm.productType" clearable placeholder="商品类型">
            <el-option v-for="item in productTypeList" :key="item.id" :label="item.type" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="input_box" v-if="this.$store.state.role === 'admin'">
          <el-select v-model="searchForm.communityIds" multiple collapse-tags style="margin-left: 20px;"
            placeholder="社区">
            <el-option v-for="item in communityList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="input_box"><el-button type="primary" class="el-icon-search" @click="searchTable"></el-button></div>
      </div>
      <div class="operate_btn">
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addProduct">添加</el-button>
        <el-button type="danger" :plain="true" icon="el-icon-delete" @click="deleteTest">批量删除</el-button>
        <el-button type="warning" icon="el-icon-s-goods" @click="batchShelves">批量上架</el-button>
        <el-button type="warning" icon="el-icon-goods" @click="undercarriageShelvesAll">批量下架</el-button>
      </div>
    </div>
    <div class="tbale_box">
      <el-table :data="tableData" border style="width: 100%" @selection-change="handleSelectionChange" height="100%">
        <el-table-column type="selection" width="55" />
        <el-table-column fixed prop="id" label="序号" width="50" />
        <el-table-column prop="productName" label="商品名称" />
        <el-table-column prop="productTypeName" label="商品类型" width="240" />
        <el-table-column prop="realMoney" label="价格(元)" width="100" />
        <el-table-column prop="exchangeScore" label="兑换积分" width="160" />
        <el-table-column prop="putawayStatus" label="状态" width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.putawayStatus == 1">待上架</span>
            <span v-if="scope.row.putawayStatus == 2">已上架</span>
            <span v-if="scope.row.putawayStatus == 3">已下架</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="250" />
        <el-table-column prop="founderName" label="创建人" width="250" />
        <el-table-column width="140" label="操作">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
              <el-button @click="editProduct(scope.row)" type="primary" icon="el-icon-edit" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top">
              <el-button type="danger" circle icon='el-icon-delete' @click="delProduct(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="上架" placement="top"
              v-if="scope.row.putawayStatus == '1' || scope.row.putawayStatus == '3'">
              <el-button type="primary" circle icon='el-icon-s-goods' @click="shelvesProduct(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="下架" placement="top" v-if="scope.row.putawayStatus == '2'">
              <el-button type="warning" circle icon='el-icon-goods'
                @click="undercarriageProduct(scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <new-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size=pageBox.pageSize
      layout="total,sizes, prev, pager, next" :total=pageBox.total>
    </new-pagination>
    <!--新增编辑-->
    <el-dialog :visible.sync="editDialog" width="50%" :close-on-click-modal="false" :close-on-press-escape="false"
      class="set_dialog_style">
      <div slot="title" class="dialog_title">
        <span></span>{{ operateBox.title }}
      </div>
      <edit-from :operateBox="operateBox" :communityList='communityList' :productTypeList="productTypeList" :key="timer"
        @closed="closed"></edit-from>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api/index'
import EditFrom from './components/edit-from'
export default {
  name: 'index',
  components: { EditFrom },
  data() {
    return {
      communityList: [],
      productTypeList: [],
      tableData: [],
      editDialog: false,
      operateBox: {
        title: '添加商品',
        operate: 'add',
        id: null
      },
      searchForm: {
        productName: '',
        productType: '',
        communityIds: null
      },
      pageBox: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      delGoodsManageIds: null,
      shelvesProductIds: null,
      timer: null
    }
  },
  created() {
    this.getProductList()
    this.fetchUserPower()
    // this.getGoodsManage(this.searchForm, this.pageBox)
    this.getCommuintyList()

  },
  methods: {
    // 获取商品管理列表
    async getGoodsManage(data, page) {
      try {
        const res = await api.getGoodsManageList(data, page)
        console.log(res);
        if (res.code == 200) {
          this.tableData = res.rows
          this.pageBox.total = res.total
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    },
    //根据UserID回显权限res,拿到医生权限管理的社区
    async fetchUserPower() {
      try {
        let res = await api.getUserPower(this.$store.state.userId)
        if (res.code == 200) {
          if (this.$store.state.role !== 'admin') {
            this.searchForm.communityIds = res.data.comId
          }
          this.getGoodsManage(this.searchForm, this.pageBox)
        }
      } catch (e) {

      }
    },
    // 获取商品类型列表
    async getProductList() {
      const data = {
        type: ''
      }
      const page = {
        pageSize: 99999,
        pageNum: 1
      }
      try {
        const res = await api.getGoodsTypeList(data, page)
        console.log(res)
        if (res.code == 200) {
          this.productTypeList = res.rows
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    },
    // 获取社区列表
    async getCommuintyList(code) {
      const data = {
        code: code
      }
      const page = {
        pageSize: 99999,
        pageNum: 1
      }
      try {
        const res = await api.getCommunitySystemUser(data, page)
        if (res.code == 200) {
          console.log("res.rows", res.rows);
          this.communityList = res.rows
        }
      } catch (e) {

      }
    },
    /* 一页显示多少条切换 */
    handleSizeChange(val) {
      this.pageBox.pageSize = val
      this.getGoodsManage(this.searchForm, this.pageBox)
    },
    /* 分页切换 */
    handleCurrentChange(val) {
      this.pageBox.pageNum = val
      this.getGoodsManage(this.searchForm, this.pageBox)
    },
    // 根据条件查询
    searchTable() {
      this.getGoodsManage(this.searchForm, this.pageBox)
    },
    // 新增样例
    addProduct() {
      this.operateBox = {
        title: '添加商品',
        operate: 'add',
        id: null
      }
      this.timer = new Date().getTime()
      this.editDialog = true
    },
    // 编辑商品管理
    editProduct(row) {
      this.operateBox = {
        title: '修改',
        operate: 'edit',
        id: row.id
      }
      this.timer = new Date().getTime()
      this.editDialog = true
    },
    // 选中要删除的试卷取对应id
    handleSelectionChange(val) {
      const ids = []
      val.forEach(item => {
        ids.push(item.id)
      })
      this.delGoodsManageIds = ids
      this.shelvesProductIds = ids
    },
    // 单个删除
    delProduct(row) {
      console.log(row)
      this.$confirm('确定删除改数据吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delGoodsManage([row.id])
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 批量删除按钮
    deleteTest() {
      if (this.delGoodsManageIds == null) {
        this.$message.info('请至少选中一件商品数据进行删除操作')
        return false
      }
      this.$confirm('确定删除该数据吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delGoodsManage(this.delGoodsManageIds)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 批量或单个删除接口
    async delGoodsManage(ids) {
      try {
        const res = await api.goodsManageDel(ids)
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '删除成功'
          })
          this.getGoodsManage(this.searchForm, this.pageBox)
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    },
    // 批量上架按钮
    batchShelves() {
      if (this.shelvesProductIds == null) {
        this.$message.info('请至少选中一件商品数据进行上架操作')
        return false
      }
      this.$confirm('确定上架该商品吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.batchShelvesGoods(this.shelvesProductIds, 2)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消上架'
        })
      })
    },
    // 批量下架
    undercarriageShelvesAll() {
      if (this.shelvesProductIds == null) {
        this.$message.info('请至少选中一件商品数据进行下架操作')
        return false
      }
      this.$confirm('确定下架该商品吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.batchShelvesGoods(this.shelvesProductIds, 3)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消上架'
        })
      })
    },
    // 商品上架或下架
    async batchShelvesGoods(ids, status) {
      try {
        const res = await api.batchShelvesProductStatus(ids, status)
        if (res.code == 200) {
          const msg = null
          if (status == 2) {
            this.msg == '上架成功'
          } else {
            this.msg == '下架成功'
          }
          this.$message({
            type: 'success',
            message: msg
          })
          this.getGoodsManage(this.searchForm, this.pageBox)
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    },
    // 单个上架商品
    shelvesProduct(row) {
      this.$confirm('确定上架该商品吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.batchShelvesGoods([row.id], 2)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },

    // 单个商品下架
    undercarriageProduct(row) {
      this.$confirm('确定下架该商品吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.batchShelvesGoods([row.id], 3)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },

    // 关闭弹窗
    closed() {
      this.editDialog = false
      this.getGoodsManage(this.searchForm, this.pageBox)
    }
  },
  watch: {}
}
</script>

<style scoped>
::v-deep .el-select__tags {
  max-width: none !important;
}

::v-deep .el-select__tags-text {
  display: inline-block;
  max-width: 6rem !important;
}
</style>