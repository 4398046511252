<template>
  <div class="form_box">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
      <el-form-item label="商品名称" prop="productName">
        <el-input v-model="ruleForm.productName"></el-input>
      </el-form-item>
      <div class="box">
        <el-form-item label="商品类型" prop="productType" placeholder="请选择">
          <el-select v-model="ruleForm.productType" clearable style="width: 100%;">
            <el-option v-for="item in productTypeList" :key="item.id" :label="item.type" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="社区" prop="communityIds" placeholder="请选择" v-if="this.$store.state.role === 'admin'">
          <el-select v-model="ruleForm.communityIds" multiple clearable style="width: 100%;">
            <el-option :label="item.name" :value="item.id" v-for="item in communityList" :key="item.id" />
          </el-select>
        </el-form-item>
      </div>
      <div class="box" style="display: flex">
        <el-form-item label="商品价格" prop="realMoney">
          <el-input-number v-model="ruleForm.realMoney" controls-position="right" @change="handleChange" :min="0.1"
            :precision="2" :step="0.01"></el-input-number>
        </el-form-item>
        <el-form-item label="兑换积分" prop="exchangeScore">
          <el-input-number v-model="ruleForm.exchangeScore" controls-position="right" :min="1"
            disabled></el-input-number>
        </el-form-item>
        <el-form-item label="最大兑换数量" prop="exchangeLimit">
          <el-input-number v-model="ruleForm.exchangeLimit" controls-position="right" :min="1"></el-input-number>
        </el-form-item>
      </div>
      <el-form-item label="商品照片" prop="productImg">
        <el-upload action="uploadUrl" list-type="picture-card" :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove" :file-list="fileList" :http-request="uploadImg">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="商品描述" prop="productIntroduce">
        <el-input v-model="ruleForm.productIntroduce" type="textarea" :rows="2"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button @click="cancelFrom('ruleForm')">取消</el-button>
      </el-form-item>
    </el-form>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api/index'
import OSS from 'ali-oss'
import moment from 'moment'
import debounce from 'lodash/debounce';
export default {
  name: 'edit-from',
  props: {
    operateBox: {
      type: Object,
      required: true
    },
    productTypeList: {
      type: Array,
      required: true
    },
    communityList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      ruleForm: {
        realMoney: null,
        exchangeScore: 10,
        exchangeLimit: null,
        productName: '',
        productType: '',
        productIntroduce: '',
        productImg: '',
        communityIds: [],
        pics: []
      },
      rules: {
        productName: [
          { required: true, message: '请输入商品名称', trigger: 'blur' }
        ],
        productIntroduce: [
          { required: true, message: '请输入商品描述', trigger: 'blur' }
        ],
        productType: [
          { required: true, message: '请选择商品类型', trigger: 'change' }
        ],
        communityIds: [
          { required: true, message: '请选择社区', trigger: 'change' }
        ],
        realMoney: [
          { required: true, message: '商品价格不为空', trigger: 'change' }
        ],
        // productImg: [
        //   { required: true, message: '图片不能为空', trigger: 'change' }
        // ],
        exchangeLimit: [
          { required: true, message: '不为空', trigger: 'change' }
        ]
      },
      ossData: {},
      fileList: []
    }
  },
  created() {
    this.fetchUserPower()
    this.ossSet()
    if (this.operateBox.id != null) {
      this.getGoodsInfo(this.operateBox.id)
    }
  },
  methods: {
    //根据UserID回显权限res,拿到医生权限管理的社区
    async fetchUserPower() {
      try {
        let res = await api.getUserPower(this.$store.state.userId)
        if (res.code == 200) {
          if (this.$store.state.role !== 'admin') {
            this.ruleForm.communityIds = res.data.comId
          }
        }
      } catch (e) {

      }
    },
    // 阿里云图片上传授权
    async ossSet() {
      try {
        const res = await api.setOss()
        if (res.httpStatus == 'OK') {
          console.log(res.data)
          this.ossData = res.data.entity
        }
      } catch (e) {

      }
    },
    // 获取商品分类详情
    async getGoodsInfo(id) {
      try {
        const res = await api.getProductInfo(id)
        if (res.code == 200) {
          console.log(res.data)
          res.data.productType = Number(res.data.productType)
          res.data.communityIds = res.data.communityIds.map(Number)
          this.ruleForm = res.data
          if (res.data.pics.length > 0) {
            res.data.pics.forEach(item => {
              this.fileList.push({
                name: '',
                url: item
              })
            })
          }
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    },
    async addProduct(data) {
      try {
        const res = await api.goodsManageAdd(data)
        if (res.code == 200) {
          this.$message.success('新增成功')
          this.$emit('closed')
        } else {
          this.$message.error(res.msg)
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    },
    async updateProduct(data) {
      try {
        const res = await api.goodsManageUpdate(data)
        if (res.code == 200) {
          this.$message.success('更新成功')
          this.$emit('closed')
        } else {
          this.$message.error(res.msg)
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    },
    handleChange(value) {
      this.ruleForm.exchangeScore = Math.round(value * 100)
    },
    submitForm: debounce(function (formName) {
      const pics = []

      if (this.fileList.length > 0) {
        this.fileList.forEach(item => {
          pics.push(item.url)
        })
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!pics.length) {
            this.$message({
              message: '请上传图片',
              type: 'error'
            })
            return
          }
          let data = this.ruleForm
          if (this.operateBox.operate == 'add') {
            data = {
              realMoney: this.ruleForm.realMoney,
              exchangeScore: this.ruleForm.exchangeScore,
              exchangeLimit: this.ruleForm.exchangeLimit,
              productName: this.ruleForm.productName,
              productType: this.ruleForm.productType,
              productIntroduce: this.ruleForm.productIntroduce,
              productImg: this.ruleForm.productImg,
              communityIds: this.ruleForm.communityIds,
              pics: pics
            }
            this.addProduct(data)
          } else {
            const data = {
              realMoney: this.ruleForm.realMoney,
              exchangeScore: this.ruleForm.exchangeScore,
              exchangeLimit: this.ruleForm.exchangeLimit,
              productName: this.ruleForm.productName,
              productType: this.ruleForm.productType,
              productIntroduce: this.ruleForm.productIntroduce,
              productImg: this.ruleForm.productImg,
              communityIds: this.ruleForm.communityIds,
              id: this.ruleForm.id,
              pics: pics
            }
            this.updateProduct(data)
          }
        } else {
          return false
        }
      })
    }, 500),
    cancelFrom() {
      this.$emit('closed')
    },
    handleRemove(file, fileList) {
      console.log(111, file, fileList)
      this.fileList = fileList
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },

    // 上传图片
    async uploadImg(option) {
      const _this = this
      try {
        const client = new OSS({
          region: 'oss-cn-beijing',
          accessKeyId: _this.ossData.accessid,
          accessKeySecret: 'QBlrMUba9AWAvBzfdVoLT3vUMcW9hO',
          bucket: 'hit-dev',
          securityToken: _this.ossData.policy
        })
        console.log('c', client)
        const file = option.file
        const point = file.name.lastIndexOf('.')
        const suffix = file.name.substr(point)
        const fileName = file.name.substr(0, point)
        const date = moment().format('YYYYMMDDHHmm')
        const relativePath = this.ossData.dir
        // let fileNames = `${fileName}_${date}${suffix}`;
        client
          .put(relativePath + fileName, file, {})
          .then(({ res, url, name }) => {
            if (res && res.status == 200) {
              this.fileList.push({
                name: name,
                url: url
              })
              this.$message({
                message: '上传成功',
                type: 'success'
              })
            }
          })
          .catch((err) => {
            this.$message({
              message: '上传失败11',
              type: 'error'
            })
          })
      } catch (error) {
        this.$message({
          message: '上传失败',
          type: 'error'
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
